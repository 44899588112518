import get from 'lodash/get'
import moment from "moment";
import store from "@/store";

const reassignLabel = {m5: '5M', m15: '15M', m30: '30M', m60: '1H', m120: '2H', m240: '4H', m720: '12H' }

export default class TickerHistory {
  constructor(init) {
    const keys = store.getters["trendsPage/percentsKeys"]
    this.id = get(init, 'id', '')

    keys.forEach(key => {
      this[key] = new TickerHistoryTrend(get(init, key, {}), key)
    })

    this.ticker = get(init, 'ticker', '')
    this.m720_current =  new TickerHistoryTrend(get(init, 'm720_current', {}), 'm720_current')
  }
}

export class TickerHistoryTrend {
  constructor(init, key) {
    this.start_timestamp = get(init, 'start_timestamp', '')
    this.timestamp = get(init, 'timestamp', '')
    this.dateStr = ''

    if (this.start_timestamp || this.timestamp) {
      this.dateStr = moment.unix(this.start_timestamp || this.timestamp).utc().format('HH:mm DD.MM.YYYY')
    }

    this.trend = get(init, 'trend', '')
    this.ma = get(init, 'ma', 0)
    this.rsi = get(init, 'rsi', 0)
    this.rsi_color = get(init, 'rsi_color', '')
    this.trendKey = key?.split('m')?.[1] || ''
  }

  get trendStatus() {
    switch (this.trend) {
      case 'up':
        return {
          emoji: '🟢',
          label: 'UPTREND'
        }
      case 'down':
        return {
          emoji: '🔴',
          label: 'DOWNTREND'
        }
      default:
        return {
          emoji: '⚪',
          label: 'UNDEFINED'
      }
    }
  }

  get maColor() {
    switch (this.ma) {
      case 3:
        return 'rgba(248, 108, 107, 0.5)'
      case 2:
        return 'rgba(255, 193, 7, 0.5)'
      case 1:
        return 'rgba(77, 189, 116, 0.5)'
      default:
        return ''
    }
  }
}

export class TrendsLogTicker {
  constructor(init) {
    this.id = get(init, 'id', '')
    this.last_update = get(init, 'last_update', '')
    this.ticker = get(init, 'ticker', '')
    this.trendsStatuses = [
      {ts: 'm30', trend: get(init, 'm30', '')},
      {ts: 'm60', trend: get(init, 'm60', '')},
      {ts: 'm120', trend: get(init, 'm120', '')},
      {ts: 'm240', trend: get(init, 'm240', '')},
      {ts: 'm720', trend: get(init, 'm720', '')},
    ]
    this.changed = get(init, 'changed', '')
    this.last_update_date = moment(this.last_update).utc().format('DD.MM.YYYY')
    this.last_update_time = moment(this.last_update).utc().format('HH:mm')
  }



  get trendsStatusesFormated(){
    this.trendsStatuses.forEach(item => {
      if(item.ts === this.changed){
        if(item.trend === 'up'){
          item.emoji = '🟩'
        }
        if(item.trend === 'down'){
          item.emoji = '🟥'
        }
      }
      if(item.ts !== this.changed){
        if(item.trend === 'up'){
          item.emoji = '🟢'
        }
        if(item.trend === 'down'){
          item.emoji = '🔴'
        }
      }
    })
    return this.trendsStatuses
  }


  get fotmatedChanged(){
    return reassignLabel[this.changed] || this.changed
  }
}
