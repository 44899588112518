var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "td",
    [
      _c(
        "v-popover",
        [
          _c(
            "div",
            {
              staticClass: "ticker-name",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) return null
                  return _vm.setShow.apply(null, arguments)
                },
              },
            },
            [_vm._t("default")],
            2
          ),
          _c(
            "template",
            { slot: "popover" },
            [
              _vm.loadingRequest
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "tooltip-inner",
                        staticStyle: { "text-align": "left", padding: "100px" },
                      },
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.loadingContent) },
                        }),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "tooltip-inner",
                        staticStyle: { "text-align": "left" },
                      },
                      [
                        _c("div", {
                          staticClass: "ticker-hint",
                          domProps: { innerHTML: _vm._s(_vm.hintContent) },
                        }),
                        _c(
                          "CButton",
                          {
                            staticClass: "cp_btn",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.copy()
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cilCopy" } })],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }