<template>
  <v-popover
    :handleResize="false"
    :open="show"
    trigger="manual"
    container=".c-app"
    placement="right"
    v-bind="popoverOptions"
    v-on="$listeners"
    @show="togglePopover(true)"
    @hide="togglePopover(false)"
  >
    <div
      class="flag-wrap"
      @click.stop="togglePopover(true)"
    >
      <CIcon
        v-for="(list, i) in currentWatchLists"
        :key="i"
        class="flag"
        :class="list.color ? `text-${list.color}` : ''"
        :style="{ color: list.customColor }"
        width="1rem"
        name="flag"
      />
      <CIcon
        v-if="!currentWatchLists.length"
        class="flag text-white"
        style="opacity: 0.1"
        width="1rem"
        name="flag"
      />
    </div>

    <template slot="popover">
      <div
        class="popup"
      >
        <transition name="fade">
          <div v-if="show" class="popup__inner">
            <button
              v-for="(list, i) in watchLists"
              :key="i"
              v-tooltip="list.name"
              class='watch-btn'
              :class="list.color ? `bg-${list.color}` : '_custom-color'"
              :style="{ background: list.customColor }"
              @click="setPair(i, isCurrentWatchList(i))"
            >
              {{ isCurrentWatchList(i) ? '✓' : '' }}
            </button>
          </div>
        </transition>
      </div>
    </template>
  </v-popover>
</template>

<script>
import screenerSettings from "@/assets/js/screenerSettings";

/**
 * @import { Signs } from "@/assets/js/PairMap.class";
 * @import PairItem from "@/assets/js/screenerSettings/PairItem.class";
 * @import Ticker from "@/assets/js/trendsDataClasses/Ticker.class";
 */

export default {
  name: "WatchSelector",
  props: {
    ticker: {
      type: String,
      default: null,
    },
    tickerObj: {
      type: Object,
      default: null,
    },
    popoverOptions: {
      type: Object,
      default: () => ({}),
    },
    showedRows: {
      type: Array,
      default: () => ([]),
    },
    currentWatchListsProp: {
      type: Array,
      default: null,
    },
    isTableHeader: {
      type: Boolean,
      default: false,
    },
    isFutures: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      settings: screenerSettings,
      show: false,
    }
  },
  computed: {
    watchLists() {
      return this.isFutures ? this.settings.watchListsBinancePerp : this.settings.watchLists
    },
    tickerName() {
      return this.ticker?.replace?.(/\.P|USDT/g, '')
    },
    currentWatchLists() {
      if (Array.isArray(this.currentWatchListsProp)) {
        return this.currentWatchListsProp;
      }
      if (this.showedRows.length) {
        return this.watchLists.filter(el =>
          this.showedRows.every(a =>
            el.pairs.some(p => (
              p?.label?.replace?.(/\.P|USDT/g, '') === a?.label?.replace?.(/\.P|USDT/g, '') ||
              p?.ticker?.replace?.(/\.P|USDT/g, '') === a?.ticker?.replace?.(/\.P|USDT/g, '') ||
              p?.label?.replace?.(/\.P|USDT/g, '') === a?.coin?.label?.replace?.(/\.P|USDT/g, '')
            ))
          )
        );
      }
      if (this.tickerName) {
        return this.watchLists.filter(el =>
          el.pairs.some(p =>
            p?.label?.replace?.(/\.P|USDT/g, '') === this.tickerName ||
            p?.ticker?.replace?.(/\.P|USDT/g, '') === this.tickerName ||
            p?.coin?.label?.replace?.(/\.P|USDT/g, '') === this.tickerName
          )
        );
      }
      return [];
    },
  },
  beforeDestroy() {
    this.togglePopover(false)
  },
  methods: {
    /**
     * Найти пару по тикеру.
     * @param {Signs} signs - Исходный тикер
     * @returns {PairItem | null}
     */
    findPair (signs) {
      const tickerName = typeof signs === 'string' ? signs : signs.ticker || signs.label || signs?.coin?.label
      const formattedTickerName = tickerName.replace(/\.P|USDT/g, '')
      return this.settings.pairsMap.findOne(formattedTickerName)
          || this.settings.tPairsMap.findOne(formattedTickerName)
    },

    setPair(listIndex, remove = false) {
      const selectedTickers = this.showedRows
      const watchList = this.watchLists[listIndex]
      if (!watchList) return console.log('item not found')

      if (this.ticker) {
        const pair = this.findPair(this.tickerName)
        if (!pair) return console.log('pair not found')

        if (remove) {
          this.removePair(watchList, pair)
        } else {
          this.addPair(watchList, pair)
        }
      }

      else if (selectedTickers.length) {
        selectedTickers.forEach(ticker => {
          const pair = this.findPair(ticker)
          if (!pair) return console.log('pair not found')

          if (remove) {
            this.removePair(watchList, pair)
          } else {
            this.addPair(watchList, pair)
          }
        })
      }

      if (this.isFutures) {
        this.settings.setWatchListsBinancePerpItem(listIndex, watchList)
        if (this.isTableHeader) {
          this.settings.promiseSaveWatchBinancePerpImmediately()
            .then(() => {
              this.$emit('getData')
            })
        } else {
          this.settings.saveWatchListsBinancePerp()
        }
      } else {
        this.settings.setWatchListsItem(listIndex, watchList)
        if (this.isTableHeader) {
          this.settings.promiseSaveWatchImmediately()
            .then(() => {
              this.$emit('getData')
            })
        } else {
          this.settings.saveWatchLists()
        }
      }
    },
    removePair(item, pair) {
      const pairIndex = item.pairs.findIndex(a => {
        return a.label.replace(/\.P|USDT/g, '') === pair.ticker.replace(/\.P|USDT/g, '')
            || a.ticker.replace(/\.P|USDT/g, '') === pair.ticker.replace(/\.P|USDT/g, '')
      })
      if (pairIndex > -1) {
        item.pairs.splice(pairIndex, 1)
        const pairStringPrefix = this.isFutures ? 'BINANCE_PERP:' : 'BINANCE:'
        const pairStringSuffix = this.isFutures ? '.P' : ''
        const pairStr = pairStringPrefix + pair.label + pairStringSuffix

        if (item.pairsString.indexOf(pairStr) === 0) {
          item.pairsString = item.pairsString.replace(pairStr, '')
        } else {
          item.pairsString = item.pairsString.replace(',' + pairStr, '')
        }
        if (item.pairsString.indexOf(',') === 0) {
          item.pairsString = item.pairsString.substring(1)
        }
        this.$emit('removePair', pair)

        if (this.tickerNameObj) {
          const index = this.tickerNameObj.watchLists.findIndex(el => el?.id === item?.id || el?.color === item?.color)
          if (index > -1) {
            this.tickerNameObj.watchLists.splice(index, 1)
          }
        }
      }
    },
    addPair(item, pair) {
      const alreadyAdded = item.pairs.some(p => {
        return p.label.replace(/\.P|USDT/g, '') === pair.label.replace(/\.P|USDT/g, '')
            || p.ticker.replace(/\.P|USDT/g, '') === pair.ticker.replace(/\.P|USDT/g, '')
      })
      if (!alreadyAdded) {
        const pairStringPrefix = this.isFutures ? 'BINANCE_PERP:' : 'BINANCE:'
        const pairStringSuffix = this.isFutures ? '.P' : ''
        const pairStr = pairStringPrefix + pair.label + pairStringSuffix

        item.pairs.push(pair)
        if (item.pairsString.length === 0) {
          item.pairsString = pairStr
        } else {
          item.pairsString += ',' + pairStr
        }
        item.pairs = item.pairs.sort((a, b) => +(a.label > b.label) || -(a.label < b.label))
        item.pairsString = item.pairsString.split(',').sort().join(',')
        if (this.tickerNameObj) {
          this.tickerNameObj.watchLists.push(item)
        }
      }
    },
    isCurrentWatchList(index) {
      if (index == null) return false;
      const list = this.watchLists[index];

      if (Array.isArray(this.currentWatchListsProp)) {
        return this.currentWatchListsProp.some(el => el?.id === list?.id);
      }
      if (this.showedRows.length) {
        return this.showedRows.every(a =>
          list.pairs.some(p =>
            p?.label?.replace?.(/\.P|USDT/g, '') === a?.label?.replace?.(/\.P|USDT/g, '') ||
            p?.ticker?.replace?.(/\.P|USDT/g, '') === a?.ticker?.replace?.(/\.P|USDT/g, '') ||
            p?.label?.replace?.(/\.P|USDT/g, '') === a?.coin?.label?.replace?.(/\.P|USDT/g, '')
          )
        );
      }
      if (this.tickerName) {
        return list.pairs.some(p =>
          p?.label?.replace?.(/\.P|USDT/g, '') === this.tickerName ||
          p?.ticker?.replace?.(/\.P|USDT/g, '') === this.tickerName ||
          p?.coin?.label?.replace?.(/\.P|USDT/g, '') === this.tickerName
        );
      }

      return false;
    },
    scrollEvent() {
      this.show = false
    },
    togglePopover(show = false) {
      this.show = show

      const scrollEl = document.querySelector('.watch-selector-parent-scroll')

      if (scrollEl) {
        if (show) {
          scrollEl.addEventListener('scroll', this.scrollEvent, {once: true})
        } else {
          scrollEl.removeEventListener('scroll', this.scrollEvent)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.flag-wrap {
  cursor: pointer;
  width: 1rem;
  display: flex;
  padding-top: 0.3rem;
  flex-direction: column;
  max-height: 100%;

  .c-icon {
    margin-top: -0.3rem;
  }
}

.popup {
  padding: 0.2rem 0.5rem;
  border-radius: .25rem;

  &__inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

.watch-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  outline: none;
  transition: all ease-in-out .2s;

  &:hover {
    &._custom-color {
      filter: brightness(80%);
    }
  }
}

.v-popover {
  width: 1rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;

  /deep/ .trigger {
    display: flex !important;
    align-items: center;
    height: 100%;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .15s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
