var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CModal", {
        staticClass: "graph-custom-modal",
        style: {
          "--width": _vm.resizerPosWidth ? _vm.resizerPosWidth + "vw" : "",
          "--height": _vm.resizerPosHeight ? _vm.resizerPosHeight + "vh" : "",
        },
        attrs: {
          show: _vm.show,
          size: "xl",
          centered: "",
          title: _vm.label + " " + _vm.tf,
          "close-on-backdrop": false,
        },
        on: { "update:show": _vm.closeGraph },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex align-items-center justify-content-between",
                  },
                  [
                    _vm.label
                      ? _c("watch-selector", {
                          attrs: {
                            ticker: _vm.formattedLabel,
                            "is-table-header": "",
                            "is-futures": _vm.isFutures,
                          },
                          on: {
                            getData: function ($event) {
                              return _vm.$emit("getData")
                            },
                          },
                        })
                      : _vm._e(),
                    _c(
                      "table-cell-ticker-content",
                      {
                        staticClass: "position-relative h5 custom-title",
                        attrs: {
                          item: {
                            symbol: _vm.formattedLabelForHistory,
                            dateTimeUnix: _vm.ts || undefined,
                          },
                          "is-futures": _vm.isFutures,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.label) +
                            " " +
                            _vm._s(_vm.altTfLabel || _vm.tf) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "d-flex" }, [
                      _vm.prevTf ||
                      (_vm.prevLabelHorizontal && _vm.fourWaySwitch)
                        ? _c(
                            "button",
                            {
                              staticClass: "control-mobile",
                              style: {
                                display:
                                  _vm.resizerPosWidth > 76 ||
                                  _vm.resizerPosHeight > 76
                                    ? "initial"
                                    : "",
                              },
                              attrs: {
                                disabled: _vm.loadingData || !_vm.chartReady,
                              },
                              on: { click: _vm.prevTfHandler },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "cbtn-icon" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-arrow-left" },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "cbtn-text" }, [
                                _vm._v(" " + _vm._s(_vm.prevTfText) + " "),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.prevLabel
                        ? _c(
                            "button",
                            {
                              staticClass: "control-mobile",
                              style: {
                                display:
                                  _vm.resizerPosHeight > 76 ||
                                  _vm.resizerPosWidth > 76
                                    ? "initial"
                                    : "",
                              },
                              attrs: {
                                disabled: _vm.loadingData || !_vm.chartReady,
                              },
                              on: { click: _vm.prevLabelHandler },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "cbtn-icon" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-arrow-top" },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "cbtn-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.textControlBtn(_vm.prevLabel, _vm.tf)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.nextLabel
                        ? _c(
                            "button",
                            {
                              staticClass: "control-mobile",
                              style: {
                                display:
                                  _vm.resizerPosHeight > 76 ||
                                  _vm.resizerPosWidth > 76
                                    ? "initial"
                                    : "",
                              },
                              attrs: {
                                disabled: _vm.loadingData || !_vm.chartReady,
                              },
                              on: { click: _vm.nextLabelHandler },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "cbtn-icon" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-arrow-bottom" },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "cbtn-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.textControlBtn(_vm.nextLabel, _vm.tf)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.nextTf ||
                      (_vm.nextLabelHorizontal && _vm.fourWaySwitch)
                        ? _c(
                            "button",
                            {
                              staticClass: "control-mobile",
                              style: {
                                display:
                                  _vm.resizerPosWidth > 76 ||
                                  _vm.resizerPosHeight > 76
                                    ? "initial"
                                    : "",
                              },
                              attrs: {
                                disabled: _vm.loadingData || !_vm.chartReady,
                              },
                              on: { click: _vm.nextTfHandler },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "cbtn-icon" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-arrow-right" },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "cbtn-text" }, [
                                _vm._v(" " + _vm._s(_vm.nextTfText) + " "),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {},
                      [
                        _vm.isFullScreen
                          ? _c("CIcon", {
                              staticClass:
                                "mr-2 cursor-pointer fullscreen-icon",
                              attrs: {
                                width: "0.8rem",
                                name: "cil-fullscreen-exit",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.toggleFullScreenMode.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        !_vm.isFullScreen
                          ? _c("CIcon", {
                              staticClass:
                                "mr-2 cursor-pointer fullscreen-icon",
                              attrs: {
                                width: "0.8rem",
                                name: "cil-fullscreen",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.toggleFullScreenMode.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _c("CIcon", {
                          staticClass: "cursor-pointer",
                          attrs: { name: "cil-x" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeGraph.apply(null, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body-wrapper",
            fn: function () {
              return [
                [
                  _vm.prevTf || (_vm.prevLabelHorizontal && _vm.fourWaySwitch)
                    ? _c(
                        "button",
                        {
                          staticClass: "control _left",
                          style: {
                            display:
                              _vm.resizerPosWidth > 76 ||
                              _vm.resizerPosHeight > 76
                                ? "none"
                                : "",
                          },
                          attrs: {
                            disabled: _vm.loadingData || !_vm.chartReady,
                            "data-text": _vm.prevTfText,
                          },
                          on: { click: _vm.prevTfHandler },
                        },
                        [_c("CIcon", { attrs: { name: "cil-arrow-left" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.nextTf || (_vm.nextLabelHorizontal && _vm.fourWaySwitch)
                    ? _c(
                        "button",
                        {
                          staticClass: "control _right",
                          style: {
                            display:
                              _vm.resizerPosWidth > 76 ||
                              _vm.resizerPosHeight > 76
                                ? "none"
                                : "",
                          },
                          attrs: {
                            disabled: _vm.loadingData || !_vm.chartReady,
                            "data-text": _vm.nextTfText,
                          },
                          on: { click: _vm.nextTfHandler },
                        },
                        [_c("CIcon", { attrs: { name: "cil-arrow-right" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.prevLabel
                    ? _c(
                        "button",
                        {
                          staticClass: "control _top",
                          style: {
                            display:
                              _vm.resizerPosHeight > 76 ||
                              _vm.resizerPosWidth > 76
                                ? "none"
                                : "",
                          },
                          attrs: {
                            disabled: _vm.loadingData || !_vm.chartReady,
                            "data-text": _vm.textControlBtn(
                              _vm.prevLabel,
                              _vm.tf
                            ),
                          },
                          on: { click: _vm.prevLabelHandler },
                        },
                        [_c("CIcon", { attrs: { name: "cil-arrow-top" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.nextLabel
                    ? _c(
                        "button",
                        {
                          staticClass: "control _bottom",
                          style: {
                            display:
                              _vm.resizerPosHeight > 76 ||
                              _vm.resizerPosWidth > 76
                                ? "none"
                                : "",
                          },
                          attrs: {
                            disabled: _vm.loadingData || !_vm.chartReady,
                            "data-text": _vm.textControlBtn(
                              _vm.nextLabel,
                              _vm.tf
                            ),
                          },
                          on: { click: _vm.nextLabelHandler },
                        },
                        [_c("CIcon", { attrs: { name: "cil-arrow-bottom" } })],
                        1
                      )
                    : _vm._e(),
                ],
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _vm.show
                      ? _c("candles-graph", {
                          staticClass: "candles-graph",
                          class: { _move: _vm.resizerMoving },
                          attrs: {
                            interval: _vm.tvInterval,
                            symbol: _vm.candlesSymbol,
                            "loading-data": _vm.loadingData,
                            confirm: _vm.stateConfirmModal,
                            confirmRemoveSignalsPlotModal:
                              _vm.stateConfirmRemoveSignalsPlotModal,
                            showConfirmRemoveSignalsPlotModal:
                              _vm.showConfirmRemoveSignalsPlotModal,
                            clientId: _vm.clientIdComp,
                          },
                          on: {
                            "update:loadingData": function ($event) {
                              _vm.loadingData = $event
                            },
                            "update:loading-data": function ($event) {
                              _vm.loadingData = $event
                            },
                            onChartReady: function ($event) {
                              _vm.chartReady = $event
                            },
                            onIntervalChanged: _vm.onIntervalChanged,
                            onPlusClick: _vm.onPlusClick,
                            editSignal: _vm.editSignal,
                            showConfirmModal: function ($event) {
                              _vm.stateConfirmModal = false
                              _vm.showConfirmModal = $event
                            },
                            showConfirmRemoveSignalsPlotModal: function (
                              $event
                            ) {
                              _vm.stateConfirmRemoveSignalsPlotModal = false
                              _vm.showConfirmRemoveSignalsPlotModal = $event
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.isDesktop || _vm.isLaptop
                      ? _c(
                          "div",
                          {
                            staticClass: "modal-resizer",
                            on: { mousedown: _vm.resizerStart },
                          },
                          [
                            _c("CIcon", {
                              staticClass: "modal-resizer__inner",
                              class: { _move: _vm.resizerMoving },
                              attrs: { name: "cil-resize-width" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.loadingData || !_vm.chartReady
                        ? _c(
                            "div",
                            { staticClass: "graph-custom-modal__loader" },
                            [
                              _c("CSpinner", {
                                staticClass: "spinner",
                                attrs: { color: "primary" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("notification-modal", {
        key: _vm.showModalKey,
        staticClass: "notification-modal",
        attrs: {
          pairsData: _vm.pairsData,
          notification: _vm.notification,
          showModal: _vm.showModal,
          isFuturesForRerender: _vm.isFuturesForRerender,
          disableMarkets: true,
          focusComment: true,
        },
        on: {
          "update:pairsData": function ($event) {
            _vm.pairsData = $event
          },
          "update:pairs-data": function ($event) {
            _vm.pairsData = $event
          },
          "update:isFuturesForRerender": function ($event) {
            _vm.isFuturesForRerender = $event
          },
          "update:is-futures-for-rerender": function ($event) {
            _vm.isFuturesForRerender = $event
          },
          close: _vm.closeEvent,
        },
      }),
      _c(
        "confirmModal",
        {
          attrs: {
            show: _vm.showConfirmModal,
            title: _vm.$t("lk.notifications.deleteNotification"),
          },
          on: {
            confirm: function ($event) {
              _vm.stateConfirmModal = true
            },
            close: function ($event) {
              _vm.showConfirmModal = false
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("lk.notifications.deleteNotificationText")) +
              " "
          ),
        ]
      ),
      _c(
        "confirmModal",
        {
          attrs: {
            show: _vm.showConfirmRemoveSignalsPlotModal,
            title: _vm.$t("tvPropsDropdown.confirmRemoveSignalsPlot") + "?",
          },
          on: {
            confirm: function ($event) {
              _vm.stateConfirmRemoveSignalsPlotModal = true
            },
            close: function ($event) {
              _vm.stateConfirmRemoveSignalsPlotModal = false
              _vm.showConfirmRemoveSignalsPlotModal = false
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("tvPropsDropdown.confirmRemoveSignalsPlotDesc")) +
              " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }