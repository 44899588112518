<template>
  <div id="container"></div>
</template>

<script>
import { widget } from '@/assets/js/charting_library'
import axios from "axios"
import initDataAutosave from "@/assets/js/trandingViewInitCandles"
import {
  getAllChartsAutosave,
  getChartContentAutosave,
  removeChartAutosave,
  saveChartAutosave,
  getAllStudyTemplates,
  saveStudyTemplate,
  getStudyTemplateContent,
  removeStudyTemplate,
  getDrawingTemplates,
  saveDrawingTemplate,
  loadDrawingTemplate,
  removeDrawingTemplate
} from "@/assets/js/delta/autosaveMethods"
import Notification from "@/assets/js/notifications/Notification.class"

import {mapGetters} from "vuex"
import studyDeltaWrap from '@/assets/js/delta/studyDeltaWrap'
import studyAccumDeltaWrap from '@/assets/js/delta/studyAccumDeltaWrap'
import studyFullAccumDeltaWrap from '@/assets/js/delta/studyFullAccumDeltaWrap'
import studyMA50_100_200 from '@/assets/js/delta/studyMA50_100_200'
import studyNatrWrap from "@/assets/js/delta/studyNatrWrap"
import studyVolatilityWrap from '@/assets/js/delta/studyVolatilityWrap'
import studyTrends from '@/assets/js/delta/studyTrends'
import studyRaketa from '@/assets/js/delta/studyRaketa'
import studyTrades from "@/assets/js/delta/studyTradesWrap"
import studyOpenInterest from '@/assets/js/delta/studyOpenInterest'
import studyCC from '@/assets/js/delta/studyCC'
import {forIn} from "lodash"
import PropsDropdown from '@/assets/js/delta/PropsDropdown.class'
import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'
import i18n from "@/i18n"
import {CLIENT_ID_DEFAULT} from "@/shared/constants"

const tvStorageUrl = axios.defaults.baseURL + '/api/v1/tv_save_load/charts'

export default {
  name: "CandlesGraph",
  props: {
    symbol: {
      type: String,
      required: true
    },
    interval: {
      type: String,
      required: true
    },
    loadingData: {
      type: Boolean,
      required: true
    },
    confirm: {
      type: Boolean,
      required: false
    },
    showConfirmRemoveSignalsPlotModal: {
      type: Boolean,
      required: false
    },
    confirmRemoveSignalsPlotModal: {
      type: Boolean,
      required: false
    },
    clientId: {
      type: String,
      default: () => CLIENT_ID_DEFAULT
    },
    hideNotificationModal:{
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      initData: null,
      tvWidget: null,
      initChartTheme: null,
      signalTimerId: null,
      varsNotification: {},
      activeSignal: [],
      activeSignals: {},
      drawnLines: {},
      signalLoading: false,
      dataConfirmModal: null,
      exchange: 'BINANCE',
      onlySymbol: null,
      toSetInterval: null,
      signalsLogList: {},
      propsDropdown: null,
      hideNotifications: false,
      hideSignalsPlot: false,
    }
  },
  async created() {
    this.$emit('onChartReady', false)
    this.$emit('update:loadingData', true)
    await this.getInitData()

    const getLocale = (value) => ['ru', 'en'].indexOf(value) !== -1 ? value : 'ru'

    const trendsDataStore = {};

    const widgetOptions = {
      symbol: this.exchangeSymbol(this.symbol),
      datafeed: new Datafeeds.UDFCompatibleDatafeed(axios.defaults.baseURL + '/api/v1/tv'),
      interval: this.interval,
      container: 'container',
      library_path: BUILD_PUBLIC_PATH + '/charting_library/',
      locale: getLocale(this.$root.$i18n.locale),
      saved_data: this.initData,
      saved_data_meta_info: {},
      save_load_adapter: {
        getAllCharts: () => getAllChartsAutosave({client_id: this.clientId}),
        removeChart: removeChartAutosave,
        saveChart: v => saveChartAutosave(v, {client_id: this.clientId}),
        getChartContent: v => getChartContentAutosave(v, {client_id: this.clientId}),

        getAllStudyTemplates: () => getAllStudyTemplates({client_id: this.clientId}),
        saveStudyTemplate: (studyTemplateData) => saveStudyTemplate(studyTemplateData, {client_id: this.clientId}),
        getStudyTemplateContent: (studyTemplateData) => getStudyTemplateContent(studyTemplateData, {client_id: this.clientId}),
        removeStudyTemplate: (studyTemplateData) => removeStudyTemplate(studyTemplateData, {client_id: this.clientId}),

        getDrawingTemplates: getDrawingTemplates,
        saveDrawingTemplate: saveDrawingTemplate,
        loadDrawingTemplate: loadDrawingTemplate,
        removeDrawingTemplate: removeDrawingTemplate,
      },
      disabled_features: [
        "timezone_menu",
        "header_widget_dom_node",
        "symbol_search_hot_key",
        "header_symbol_search",
        "header_compare",
        "header_undo_redo",

        // 'use_localstorage_for_settings',
        // 'header_resolutions',

        // 'header_saveload',
        // 'left_toolbar',

        // 'timeframes_toolbar',
        'volume_force_overlay',
        // 'header_indicators',
        //'header_settings',
        'main_series_scale_menu'
      ],
      enabled_features: this.enabledFeatures,
      client_id: this.clientId,
      user_id: this.$store.state.user.userData.id,
      auto_save_delay: 5,
      fullscreen: false,
      autosize: true,
      theme: this.$store.getters.themeCapitalize,
      custom_indicators_getter: (PineJS) => {
        return Promise.resolve([
          studyDeltaWrap(PineJS),
          studyAccumDeltaWrap(PineJS, this.tvWidget),
          studyFullAccumDeltaWrap(PineJS),
          studyMA50_100_200(PineJS),
          studyNatrWrap(PineJS),
          studyVolatilityWrap(PineJS, this.tvWidget),
          ...(this.isStudyTrendsEnabled ? [studyTrends(PineJS, trendsDataStore), studyRaketa(PineJS, [])] : []),
          studyTrades(PineJS),
          studyOpenInterest(PineJS),
          studyCC(PineJS),
        ])
      },
    }

    let enable_features = localStorage.getItem('tv_enable_features');
    if (enable_features) {
      enable_features = enable_features.split(/\W+/);
      widgetOptions.disabled_features = widgetOptions.disabled_features.filter(v => !enable_features.includes(v));
      widgetOptions.debug = enable_features.includes('debug')
      if (widgetOptions.debug) window.tvWidget = this.tvWidget
    }

    if (this.initData.id) {
      widgetOptions.saved_data_meta_info = {
        uid: this.initData.id,
        name: this.initData.name,
        description: this.initData.description
      }
    }

    Datafeeds.marketRequest = {}
    if(this.isStudyTrendsEnabled){
      Datafeeds.trendsRequest = {
        enable: 1
      }
    }
    window.addEventListener('signalsData', e => {
      this.signalsUpdate(e.detail || [])
    })
    window.addEventListener('signalsLogData', e => {
      this.signalsLogUpdate(e.detail)
    })

    window.addEventListener('trendsData', e => {
      const key = e.detail.symbol + '_' + e.detail.resolution
      if(!trendsDataStore[key]){
        trendsDataStore[key] = [[], [], [], []]
      }
      e.detail.data.forEach((ots, k) => {
        ots.forEach(ot => {
          if(!trendsDataStore[key][k].includes(ot)){
            trendsDataStore[key][k].push(ot)
          }
        })
      })
    })

    /**
     * @type IChartingLibraryWidget
     */
    this.tvWidget = new widget(widgetOptions)

    this.tvWidget.onChartReady(this.onChartReady)

    this.propsDropdown = new PropsDropdown(this.tvWidget, {
      hideNotifications: (hide) => {
        this.hideNotifications = hide
        this.drawSignalLine()
      },
      hideSignalsPlot: (hide) => {
        this.hideSignalsPlot = hide
        this.signalsLogUpdate()
      },
      removeSignalsPlot: (pair, removed) => {
        this.signalsLogRemove(removed)
        if(removed.length){
          this.$toastr.success(this.$t('tvPropsDropdown.signalsPlotRemoved', {pair: pair}))
        }else{
          this.$toastr.warning(this.$t('tvPropsDropdown.signalsPlotRemoveNone', {pair: pair}))
        }
      }
    })
    this.hideNotifications = this.propsDropdown.hideNotifications
    this.hideSignalsPlot = this.propsDropdown.hideSignalsPlot
  },
  mounted() {
    this.$root.$on('trendsNotificationCreated', () => {
      this.getSignals()
    })
  },
  computed: {
    ...mapGetters({
      userTariffId: 'user/tariff',
      getPermissions: 'user/getPermissions',
    }),
    userData() {
      return this.$store.state.user.userData
    },
    isProTariff() {
      return this.userTariffId === 'pro'
    },
    isProTrialTariff() {
      return this.userTariffId && this.userTariffId.includes('trial_')
    },
    enabledFeatures() {
      const arr = [
        'study_templates'
      ]

      if (this.userData.getAccess(1) && !this.hideNotificationModal) {
        arr.push('chart_crosshair_menu')
      }

      return arr
    },
    isStudyTrendsEnabled() {
      return this.getPermissions('view_trends')// && this.clientId === 'trends'
    }
  },
  destroyed() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove()
      this.tvWidget = null
    }

    this.$emit('onChartReady', false)
  },
  watch: {
    symbol (value) {
      this.$emit('update:loadingData', true)
      this.tvWidget.activeChart().setSymbol(this.exchangeSymbol(value), ()=>{})
      this.signalsUpdate()
    },
    interval (value) {
      this.signalsLogRemove(Object.keys(this.signalsLogList), true)
      this.$emit('update:loadingData', true)
      this.tvWidget.activeChart().setResolution(value, ()=>{})
    },
    activeSignal() {
      setTimeout(() => {
        this.drawSignalLine()
      })
    },
    confirm(val) {
      if(val){
        this.deleteDraw()
      }
    },
    'propsDropdown.showConfirmRemoveSignalsPlotModal': function (val) {
      this.$emit('showConfirmRemoveSignalsPlotModal', val)
    },
    showConfirmRemoveSignalsPlotModal(val) {
      this.propsDropdown.showConfirmRemoveSignalsPlotModal = val
    },
    confirmRemoveSignalsPlotModal(val) {
      if(val){
        this.propsDropdown.removeSignalsPlotCallback()
      }
    }
  },
  beforeDestroy() {
    window.clearInterval(this.signalTimerId)
    this.$root.$off('trendsNotificationCreated')
  },
  methods: {
    openCreateSignalModal(exchange, symbol, price){
      this.$emit('onPlusClick', {
        price,
        symbol:`${exchange}:${symbol}`
      })
    },
    contextCreateSignalHandler(price){
      const exchange = this.getExchange()
      const symbol = this.getOnlySymbol()
      this.openCreateSignalModal(exchange, symbol, price)
    },
    addContextItem(unix, price){
      return [
        {
          position: "bottom",
          text: i18n.t('lk.notifications.createSignal'),
          click: ()=> this.contextCreateSignalHandler(price)
        }
      ];
    },
    onChartReady() {
      //@todo bugfix lib tv v26
      if(this.symbol){
        this.tvWidget.activeChart().setSymbol(this.exchangeSymbol(this.symbol), ()=>{})
      }
      if(this.interval){
        this.tvWidget.activeChart().setResolution(this.interval, ()=>{})
      }

      if(!this.initChartTheme || this.$store.getters.theme !== this.initChartTheme){
        const options = { saveSymbol: true, saveInterval: true };
        const template = this.tvWidget.activeChart().createStudyTemplate(options);

        this.tvWidget.changeTheme(this.tvWidget._options.theme)

        this.$nextTick(() => {
          this.tvWidget.activeChart().applyStudyTemplate(template);
        })
      }
      this.tvWidget.activeChart().dataReady(this.dataReady)
      this.tvWidget.onContextMenu(this.addContextItem);
      // this.tvWidget.activeChart().createStudy('Moving Average', false, false, [200])
      // this.tvWidget.activeChart().createStudy('Moving Average', false, false, [100])
      // this.tvWidget.activeChart().createStudy('Moving Average', false, false, [50])
      // this.tvWidget.activeChart().createStudy('Relative Strength Index', false, false)
      this.tvWidget.subscribe('onAutoSaveNeeded', () => this.tvWidget.saveChartToServer(null, null, {defaultChartName: 'autosave'}))
      this.tvWidget.activeChart().onDataLoaded().subscribe(null, () => {
        this.$emit('update:loadingData', false)
      }, false)
      this.tvWidget.activeChart().onIntervalChanged().subscribe(null, (interval) => {
        this.$emit('onIntervalChanged', interval)
      });
      this.tvWidget.subscribe('onPlusClick', (coordinates) => {
        this.$emit('onPlusClick', coordinates)
      });
      this.$emit('onChartReady', true)
      this.getNotification()
    },
    getNotification() {
      let result = {}
      forIn(Notification.vars, (section, sectionKey) => {
        forIn(section.items, (value, key) => {
          result[key] = value
        })
      })
      this.varsNotification = result;
    },
    getOnlySymbol() {
      let symbol = null
      try{
        symbol = this.symbol || this.tvWidget.activeChart().symbolExt().symbol
      }catch($e){

      }
      return symbol ? symbol.split(':').pop().toUpperCase() : ''
    },
    getExchange() {
      let exchange = null
      try{
        exchange = this.exchange || this.tvWidget.activeChart().symbolExt().exchange
      }catch(e){}
      return exchange ? exchange.toUpperCase() : ''
    },
    getInterval() {
      try{
        return this.interval || this.tvWidget?.symbolInterval()?.interval || this.initData?.interval || this.initData?.resolution || '60'
      }catch(e){}
      return ''
    },
    signalsLogCreateShape(id, timeout = null){
      setTimeout(() => {
        if(this.hideSignalsPlot){
          return
        }
        if(this.signalsLogList[id] && !this.signalsLogList[id].shape
          && this.signalsLogList[id].exchange === this.getExchange()
          && this.signalsLogList[id].symbol === this.getOnlySymbol())
        {
          try{
            if(this.tvWidget?.activeChart()){
              let icon = this.signalsLogList[id].icon || 0xf0a2
              if(icon === 0xf132){
                icon = 0xf071
              }
              this.signalsLogList[id].shape = this.tvWidget.activeChart().createShape(
                {
                  time: this.signalsLogList[id].time,
                  price: this.signalsLogList[id].price
                },
                {
                  shape: 'icon',
                  lock: true,
                  disableSelection: true,
                  disableSave: true,
                  disableUndo: true,
                  showInObjectsTree: false,
                  zOrder: 'top',
                  overrides: {
                    icon: icon,
                    size: this.signalsLogList[id].size || 19,
                    color: this.signalsLogList[id].color
                  }
                }
              )
            }
          } catch(e){
            console.error(e);
          }
          if(this.signalsLogList[id].shape){
            this.signalsLogList[id].interval = this.getInterval()
          } else if(timeout < 500) {
            this.signalsLogCreateShape(id, timeout === null ? 100 : timeout + 100)
          }
        }
      }, timeout)
    },
    signalsLogUpdate(resp){
      const exchange = this.getExchange()
      const symbol = this.getOnlySymbol()
      const interval = this.getInterval()
      if(resp === undefined || this.hideSignalsPlot){
        Object.keys(this.signalsLogList).forEach(id => {
          if(this.hideSignalsPlot || this.signalsLogList[id].exchange !== exchange || this.signalsLogList[id].symbol !== symbol || this.signalsLogList[id].interval !== interval){
            if(this.signalsLogList[id].shape && this.tvWidget){
              this.tvWidget.activeChart().removeEntity(this.signalsLogList[id].shape, {
                disableUndo: true
              })
              this.signalsLogList[id].shape = null
              delete this.signalsLogList[id].interval
            }
          }
          if (!this.hideSignalsPlot && !this.signalsLogList[id].shape && this.signalsLogList[id].exchange === exchange && this.signalsLogList[id].symbol === symbol) {
            this.signalsLogCreateShape(id, 50)
          }
        })
        if(resp === undefined){
          return
        }
      }
      const tmp = resp.symbol.split(':')
      const respExchange = (tmp[0] || exchange).toUpperCase()
      const respSymbol = (tmp[1] || symbol).toUpperCase()
      resp.symbol.split(':')
      resp.data.forEach(item => {
        if(!this.signalsLogList[item.id]){
          this.signalsLogList[item.id] = item
        }
        this.signalsLogList[item.id].exchange = respExchange
        this.signalsLogList[item.id].symbol = respSymbol
        this.signalsLogList[item.id].interval = interval
        if(!this.hideSignalsPlot){
          this.signalsLogCreateShape(item.id)
        }
      })
    },
    signalsLogRemove(ids = [], onlyShape = false){
      ids.forEach((id) => {
        if(!this.signalsLogList[id]){
          return
        }
        if(this.signalsLogList[id].shape && this.tvWidget){
          this.tvWidget.activeChart().removeEntity(this.signalsLogList[id].shape, {
            disableUndo: true
          })
        }
        if(onlyShape){
          this.signalsLogList[id].shape = null
          delete this.signalsLogList[id].interval
        }else{
          delete this.signalsLogList[id]
        }
      })
      setTimeout(() => {
        this.signalsLogUpdate()
      }, 100)
    },
    signalsUpdate(resp) {
      if(resp === undefined){
        this.activeSignal = this.activeSignals[this.exchange + ':' + this.onlySymbol] || []
        return
      }
      let result = []
      resp.filter(item => item.id && item.conditions && item.pair && item.pair.exchange === this.exchange && item.pair.symbol === this.onlySymbol).forEach(el => {
        let k = 0;
        el.conditions.forEach(element => {
          result.push(Object.assign(element, {
            is_single: el.is_single,
            signalId: Number(el.id),
            comment: el.comment,
            k: k
          }))
          k++
        })
      })
      this.activeSignal = result
      this.activeSignals[this.exchange + ':' + this.onlySymbol] = this.activeSignal
    },
    async getSignals() {
      if(!this.signalLoading){
        this.signalLoading = true
        if(!this.exchange || !this.onlySymbol){
          return;
        }
        const res = await axios.get('api/v1/tv_signals', {
          params: {
            tv: 1,
            exchange: this.exchange,
            symbol: this.onlySymbol
          }
        })
        if(res?.status < 300){
          this.signalsUpdate(res?.data?.data || [])
        }
        this.signalLoading = false
      }
    },
    deleteDraw() {
      this.$emit('showConfirmModal', false)
      axios.delete('api/v1/signals', {
        data: {
          id: this.dataConfirmModal.signalId
        }
      }).then(() => {
        this.$toastr.success(this.$t('lk.notifications.removeSuccess'))
        if(this.drawnLines[this.dataConfirmModal.signalId]){
          Object.keys(this.drawnLines[this.dataConfirmModal.signalId]).forEach(id => this.drawnLines[this.dataConfirmModal.signalId][id]._line && this.drawnLines[this.dataConfirmModal.signalId][id].remove())
          delete this.drawnLines[this.dataConfirmModal.signalId]
        }
      }).catch((e) => {
        if(e.response.status === 404){
          if(this.drawnLines[this.dataConfirmModal.signalId]){
            Object.keys(this.drawnLines[this.dataConfirmModal.signalId]).forEach(id => this.drawnLines[this.dataConfirmModal.signalId][id]._line && this.drawnLines[this.dataConfirmModal.signalId][id].remove())
            delete this.drawnLines[this.dataConfirmModal.signalId]
          }
        }
      })
    },
    drawSignalLine() {
      try{
        Object.keys(this.drawnLines).forEach(el => {
          el = Number(el)
          if(this.hideNotifications || !this.activeSignal.some(item => item.signalId === el)) {
            let isDelete = true
            Object.keys(this.drawnLines[el]).forEach(id => {
              if(this.drawnLines[el][id].tmp_position){
                isDelete = false
              }else if(this.drawnLines[el][id]._line){
                this.drawnLines[el][id].remove()
              }
            })
            if(isDelete){
              delete this.drawnLines[el]
            }
          }
        })
      }catch(e){

      }

      if(this.hideNotifications){
        return
      }

      this.activeSignal.forEach(el => {
        if(!this.drawnLines[el.signalId]){
          this.drawnLines[el.signalId] = {}
        }
        try{
          if(!this.drawnLines[el.signalId][el.k]) {
            this.drawnLines[el.signalId][el.k] = this.tvWidget.activeChart().createOrderLine()
              .setPrice(el.position)
              .setText(`${el.tf} ${this.varsNotification[el.var]} ${el.value}`)
              .setQuantity('✏️')
              .onModify(res => {
                this.$emit('editSignal', el)
              })
              .setTooltip(el.comment || '')
              .setCancelTooltip(this.$t('lk.notifications.deleteNotification'))
            if(el.is_single){
              this.drawnLines[el.signalId][el.k]
                .onCancel(() => {
                this.$emit('showConfirmModal', true)
                this.dataConfirmModal = el
              })
                .onMove(async () => {
                  this.drawnLines[el.signalId][el.k].tmp_position = this.drawnLines[el.signalId][el.k].getPrice()
                  this.drawnLines[el.signalId][el.k].setEditable(false)
                  try {
                    const res = await axios.post('api/v1/signals/change_position/' + el.signalId, {
                      k: el.k,
                      position: this.drawnLines[el.signalId][el.k].tmp_position
                    })
                    if(res?.data?.success){
                      this.$toastr.success(res?.data?.message || this.$t('lk.notifications.signalChanged'))
                    }else if(res?.data?.message) {
                      this.$toastr.warning(res.data.message)
                    }
                    await this.getSignals()
                  } catch(e){

                  }
                  this.drawnLines[el.signalId][el.k].setEditable(true)
                  delete this.drawnLines[el.signalId][el.k].tmp_position
                })
            }
            if(el.tf === 'price'){
              this.drawnLines[el.signalId][el.k]
                .setLineColor("rgb(150, 100, 200)")
                .setCancelButtonIconColor("rgb(150, 100, 200)")
                .setBodyBorderColor("rgb(150, 100, 200)")
                .setCancelButtonBorderColor("rgb(150, 100, 200)")
                .setBodyTextColor("rgb(150, 100, 200)")
                .setBodyBackgroundColor("rgba(150, 100, 200, 0.25)")
                .setCancelButtonBackgroundColor("rgba(120, 80, 180, 0.4)")
                .setQuantityBackgroundColor("rgb(150, 100, 200, 0.25)")
                .setQuantityBorderColor("rgb(150, 100, 200)")
            }else{
              this.drawnLines[el.signalId][el.k]
                .setQuantityBackgroundColor("rgba(255, 255, 255, 0.75)")
            }
          }else{
            this.drawnLines[el.signalId][el.k]
              .setPrice(this.drawnLines[el.signalId][el.k].tmp_position || el.position)
              .setText(`${el.tf} ${this.varsNotification[el.var]} ${el.value}`)
              .setTooltip(el.comment || '')
          }
        }catch(e){}
      })
    },
    async getInitData () {
      // todo либо получаем последнее сохранение с сервера, либо применяем начальный конфиг (для первого входа юзера)
      if (!this.initData){
        const result = await axios.get(tvStorageUrl, {
          params: {lastContentJson: 1, client_id: this.clientId}
        })
        if (result.data && result.data.data) {
          this.initData = result.data.data
          this.initChartTheme = result.data.theme ? result.data.theme.toLowerCase() : null
        } else {
          this.initData = initDataAutosave
        }
      }

      const exchangeSymbol = this.exchangeSymbol(this.symbol)
      const currencyId = this.getCurrencyCode(exchangeSymbol)

      this.initData.symbol = exchangeSymbol
      this.initData.interval = this.interval
      if(this.initData.charts && this.initData.charts[0] && this.initData.charts[0].panes && this.initData.charts[0].panes[0]){
        if(this.initData.charts[0].panes[0].sources && this.initData.charts[0].panes[0].sources[0] && this.initData.charts[0].panes[0].sources[0].state) {
          this.initData.charts[0].panes[0].sources[0].state.symbol = exchangeSymbol
          this.initData.charts[0].panes[0].sources[0].state.currencyId = currencyId
          this.initData.charts[0].panes[0].sources[0].state.interval = this.interval

          if(!this.initData.charts[0].panes[0].sources[0].state.priceAxisProperties) {
            this.initData.charts[0].panes[0].sources[0].state.priceAxisProperties = {}
          }

          this.initData.charts[0].panes[0].sources[0].state.priceAxisProperties.autoScale = true
        }
        if(this.initData.charts[0].panes[0].rightAxisesState && this.initData.charts[0].panes[0].rightAxisesState[0] && this.initData.charts[0].panes[0].rightAxisesState[0].state){
          this.initData.charts[0].panes[0].rightAxisesState[0].state.m_isAutoScale = true
        }
      }
      return this.initData
    },
    dataReady () {
      // todo по id смотрю начальный это конфиг или загруженный, если начальный,
      // значит еще нет сохранений и надо сразу сохранить, чтобы в дальнейшем дублей не возникло
      if (!this.initData.id) {
        this.tvWidget.saveChartToServer(null, null, {
          defaultChartName: 'autosave'
        })
      }
      // this.tvWidget.save((state) => {
      //   console.log(888,state)
      // })
    },
    exchangeSymbol(symbol, defaultExchange = 'BINANCE') {
      symbol = symbol.toUpperCase()
      if(symbol.includes(':')){
        const arr = symbol.split(':')
        this.exchange = arr[0]
        this.onlySymbol = arr[1]
        return symbol
      }
      this.exchange = defaultExchange.toUpperCase()
      this.onlySymbol = symbol
      return this.exchange + ':' + symbol
    },
    getCurrencyCode(symbol) {
      return ['USDT', 'USDC', 'TUSD', 'USD'].find(c => (new RegExp(c + '$')).test(symbol)) || 'USDT'
    },
  }
}
</script>

<style lang="scss" scoped>
#container {
  height: 100%;
}
</style>
