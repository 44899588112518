export default function (interval) {
  switch (interval) {
    case '1': return '1m'
    case '5': return '5m'
    case '15': return '15m'
    case '30': return '30m'
    case '60': return '1h'
    case '120': return '2h'
    case '240': return '4h'
    case '720': return '12h'
    case '1D': return '1d'
    case '1W': return '1w'
    default:
      console.warn('interval not valid')
      return '1h'
  }
}
