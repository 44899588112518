export default function (tf) {
  switch (tf) {
    case '1m': return '1'
    case '5m': return '5'
    case '15m': return '15'
    case '30m': return '30'
    case '1h': return '60'
    case '2h': return '120'
    case '4h': return '240'
    case '12h': return '720'
    case '1d': return '1D'
    default:
      console.warn('timeframe not valid')
      return '60'
  }
}
