<template>
  <div>
    <CModal
      :show="show"
      size="xl"
      centered
      class="graph-custom-modal"
      :title="label+' '+tf"
      :close-on-backdrop="false"
      :style="{ '--width': resizerPosWidth ? resizerPosWidth + 'vw' : '','--height': resizerPosHeight ? resizerPosHeight + 'vh' : '' }"
      @update:show="closeGraph"
    >
      <template #header>
        <div class="w-100 d-flex align-items-center justify-content-between">
          <watch-selector
            v-if="label"
            :ticker="formattedLabel"
            is-table-header
            :is-futures="isFutures"
            @getData="$emit('getData')"
          />
          <table-cell-ticker-content
            :item="{symbol: formattedLabelForHistory, dateTimeUnix: ts || undefined }"
            :is-futures="isFutures"
            class="position-relative h5 custom-title"
          >
            {{ label }} {{ altTfLabel || tf }}
          </table-cell-ticker-content>
          <div class="d-flex">
            <button
              v-if="prevTf || prevLabelHorizontal && fourWaySwitch"
              :disabled="loadingData || !chartReady"
              :style="{ 'display': resizerPosWidth > 76 || resizerPosHeight > 76 ? 'initial' : '' }"
              @click="prevTfHandler"
              class="control-mobile"
            >
              <span class="cbtn-icon">
                <CIcon name="cil-arrow-left" />
              </span>
              <span class="cbtn-text">
                {{ prevTfText }}
              </span>
            </button>
            <button
              v-if="prevLabel"
              :disabled="loadingData || !chartReady"
              class="control-mobile"
              :style="{ 'display': resizerPosHeight > 76 || resizerPosWidth > 76 ? 'initial' : '' }"
              @click="prevLabelHandler"
            >
              <span class="cbtn-icon">
                <CIcon name="cil-arrow-top" />
              </span>
              <span class="cbtn-text">
                {{textControlBtn(prevLabel, tf)}}
              </span>
            </button>
            <button
              v-if="nextLabel"
              :disabled="loadingData || !chartReady"
              class="control-mobile"
              :style="{ 'display': resizerPosHeight > 76 || resizerPosWidth > 76 ? 'initial' : '' }"
              @click="nextLabelHandler"
            >
              <span class="cbtn-icon">
                <CIcon name="cil-arrow-bottom" />
              </span>
              <span class="cbtn-text">
                {{textControlBtn(nextLabel, tf)}}
              </span>
            </button>
            <button
              v-if="nextTf || nextLabelHorizontal && fourWaySwitch"
              :disabled="loadingData || !chartReady"
              :style="{ 'display': resizerPosWidth > 76 || resizerPosHeight > 76 ? 'initial' : '' }"
              @click="nextTfHandler"
              class="control-mobile"
            >
              <span class="cbtn-icon">
                <CIcon name="cil-arrow-right" />
              </span>
              <span class="cbtn-text">
                {{ nextTfText }}
              </span>
            </button>
          </div>
          <div class="">
            <CIcon
              v-if="isFullScreen"
              width="0.8rem"
              class="mr-2 cursor-pointer fullscreen-icon"
              name="cil-fullscreen-exit"
              @click.native="toggleFullScreenMode" />
            <CIcon
              v-if="!isFullScreen"
              width="0.8rem"
              class="mr-2 cursor-pointer fullscreen-icon"
              name="cil-fullscreen"
              @click.native="toggleFullScreenMode" />
            <CIcon
              class="cursor-pointer"
              name="cil-x"
              @click.native="closeGraph" />
          </div>
        </div>
      </template>
      <template #body-wrapper>
        <template>
          <button
            v-if="prevTf || prevLabelHorizontal && fourWaySwitch"
            :disabled="loadingData || !chartReady"
            :style="{ 'display': resizerPosWidth > 76 || resizerPosHeight > 76 ? 'none' : '' }"
            @click="prevTfHandler"
            :data-text="prevTfText"
            class="control _left"
          >
            <CIcon name="cil-arrow-left" />
          </button>
          <button
            v-if="nextTf || nextLabelHorizontal && fourWaySwitch"
            :disabled="loadingData || !chartReady"
            :style="{ 'display': resizerPosWidth > 76 || resizerPosHeight > 76 ? 'none' : '' }"
            @click="nextTfHandler"
            :data-text="nextTfText"
            class="control _right"
          >
            <CIcon name="cil-arrow-right" />
          </button>
          <button
            v-if="prevLabel"
            :disabled="loadingData || !chartReady"
            class="control _top"
            :data-text="textControlBtn(prevLabel, tf)"
            :style="{ 'display': resizerPosHeight > 76 || resizerPosWidth > 76 ? 'none' : '' }"
            @click="prevLabelHandler"
          >
            <CIcon name="cil-arrow-top" />
          </button>
          <button
            v-if="nextLabel"
            :disabled="loadingData || !chartReady"
            class="control _bottom"
            :data-text="textControlBtn(nextLabel, tf)"
            :style="{ 'display': resizerPosHeight > 76 || resizerPosWidth > 76 ? 'none' : '' }"
            @click="nextLabelHandler"
          >
            <CIcon name="cil-arrow-bottom" />
          </button>
        </template>
        <div class="modal-body">
          <candles-graph
            v-if="show"
            :interval="tvInterval"
            :symbol="candlesSymbol"
            :loading-data.sync="loadingData"
            class="candles-graph"
            :class="{ _move: resizerMoving }"
            :confirm="stateConfirmModal"
            :confirmRemoveSignalsPlotModal="stateConfirmRemoveSignalsPlotModal"
            :showConfirmRemoveSignalsPlotModal="showConfirmRemoveSignalsPlotModal"
            :clientId="clientIdComp"
            @onChartReady="chartReady = $event"
            @onIntervalChanged="onIntervalChanged"
            @onPlusClick="onPlusClick"
            @editSignal="editSignal"
            @showConfirmModal="stateConfirmModal = false; showConfirmModal = $event"
            @showConfirmRemoveSignalsPlotModal="stateConfirmRemoveSignalsPlotModal = false; showConfirmRemoveSignalsPlotModal = $event"
          />
          <!-- <notification-modal
            :pairsData.sync="pairsData"
            :notification="notification"
            :showModal="showModal"
            class="notification-modal"
            :isTvEdit="isTvEdit"
            :signalId="signalId"
            @close="showModal = false; isTvEdit = false; signalId = null"
          />
          <NotificationInfoModal
            v-if="showInfoNotificationModal"
            :show="showInfoNotificationModal"
            :currentNotification="notification"
            class="notification-modal"
            :isTvEdit="isTvEdit"
            :signalId="signalId"
            @close="showInfoNotificationModal = false; isTvEdit = false; signalId = null"
          />
          <confirmModal
            :show="showConfirmModal"
            :title="$t('lk.notifications.deleteNotification')"
            @confirm="stateConfirmModal = true;"
            @close="showConfirmModal = false"
          >
            {{ $t('lk.notifications.deleteNotificationText') }}
          </confirmModal>
          <confirmModal
            :show="showConfirmRemoveSignalsPlotModal"
            :title="$t('tvPropsDropdown.confirmRemoveSignalsPlot') + '?'"
            @confirm="stateConfirmRemoveSignalsPlotModal = true"
            @close="stateConfirmRemoveSignalsPlotModal = false; showConfirmRemoveSignalsPlotModal = false"
          >
            {{ $t('tvPropsDropdown.confirmRemoveSignalsPlotDesc') }}
          </confirmModal> -->
          <div v-if="isDesktop || isLaptop" class="modal-resizer" @mousedown="resizerStart">
            <CIcon name="cil-resize-width" :class="{ _move: resizerMoving }" class="modal-resizer__inner"/>
          </div>
          <transition name="fade">
            <div v-if="loadingData || !chartReady" class="graph-custom-modal__loader">
              <CSpinner
                class="spinner"
                color="primary"
              />
            </div>
          </transition>
        </div>
      </template>
    </CModal>


    <notification-modal
      class="notification-modal"
      :key="showModalKey"
      :pairsData.sync="pairsData"
      :notification="notification"
      :showModal="showModal"
      :isFuturesForRerender.sync="isFuturesForRerender"
      :disableMarkets="true"
      :focusComment="true"
      @close="closeEvent"
    />

    <confirmModal
      :show="showConfirmModal"
      :title="$t('lk.notifications.deleteNotification')"
      @confirm="stateConfirmModal = true;"
      @close="showConfirmModal = false"
    >
      {{ $t('lk.notifications.deleteNotificationText') }}
    </confirmModal>
    <confirmModal
      :show="showConfirmRemoveSignalsPlotModal"
      :title="$t('tvPropsDropdown.confirmRemoveSignalsPlot') + '?'"
      @confirm="stateConfirmRemoveSignalsPlotModal = true"
      @close="stateConfirmRemoveSignalsPlotModal = false; showConfirmRemoveSignalsPlotModal = false"
    >
      {{ $t('tvPropsDropdown.confirmRemoveSignalsPlotDesc') }}
    </confirmModal>
  </div>
</template>

<script>
import TableCellTickerContent from "@/components/trends/tablesGroup/TableCellTickerContent.vue";
import axios from "axios"
import CandlesGraph from "@/components/screener/tables/CandlesGraph.vue"
import tfToInterval from "@/assets/js/delta/tfToInterval"
import intervalToTf from "@/assets/js/delta/intervalToTf"
import WatchSelector from "@/components/screener/WatchSelector.vue"
import {mapGetters} from "vuex"
import Notification from "@/assets/js/notifications/Notification.class"
import Condition from "@/assets/js/notifications/Condition.class"
import NotificationModal from "@/components/notifications/NotificationModal"
import confirmModal from "@/components/common/confirmModal"
import throttle from "lodash/throttle"
import {CLIENT_ID_DEFAULT, CLIENT_ID_CANDLES_BINANCE_FUTURES} from "@/shared/constants";
import { cloneDeep } from "lodash";

const RESIZER_POS_CANDIES_GRAPH_MODAL = 'resizerPosCandiesGraphModal'
const RESIZER_HEIGHT_CANDIES_GRAPH_MODAL = 'resizerPosHeightCandiesGraphModal'

let initialNotify
const createInitialNotify = (isFuture) => initialNotify = new Notification({
  is_futures: isFuture,
  conditions: [new Condition()]
})

export default {
  name: 'CandlesGraphModal',
  components: {
    CandlesGraph,
    WatchSelector,
    NotificationModal,
    confirmModal,
    TableCellTickerContent
  },
  props: {
    rows: {
      type: Array,
      default: () => ([])
    },
    settings: Object,
    isMinutes: {
      type: Boolean,
      default: false
    },
    fourWaySwitch: {
      type: Boolean,
      default: false
    },
    isCoinDuplicate:{
      type: Boolean,
      default: false
    },
    clientId: {
      type: String,
      default: ''
    },
    hideNotificationModal:{
      type: Boolean,
      default: false
    },
    tablesIntervalsForUse: {
      type: Array,
      default: () => []
    },
    isFutures: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      label: null,
      currentItemId: null,
      tfIndex: null,
      chartReady: false,
      loadingData: true,
      notification: createInitialNotify(this.isFutures),
      pairsData: null,
      showModalKey: 1,
      showModal: false,
      resizerMoving: false,
      resizerPosWidth: null,
      resizerPosHeight: null,
      showConfirmModal: false,
      stateConfirmModal: false,
      showConfirmRemoveSignalsPlotModal: false,
      stateConfirmRemoveSignalsPlotModal: false,
      altTfLabel: '',
      ts: null,
      tfExceptions: ['1W', '1'],
      isFuturesForRerender: false,
    }
  },
  computed: {
    ...mapGetters({
      pairs: 'pairs',
      isMobile: 'mediaQuery/isMobile',
      isDesktop: 'mediaQuery/isDesktop',
      isLaptop: 'mediaQuery/isLaptop',
    }),
    formattedLabelForHistory () {
      const isBinance = this.label?.startsWith('BINANCE') || this.label?.startsWith('BINANCE_PERP')
      return isBinance ? this.formattedLabel : this.label
    },
    formattedLabel () {
      const label = this.isFutures ? this.label?.replace('USDT', '') : this.label
      return label?.split(':')?.at(-1);
    },
    candlesSymbol(){
      const value = this.label

      const isPair = value && value.includes(':');
      const [ exchange, coin ] = isPair ? value.split(':') : [ 'BINANCE', value]

      const coinIsPerp = coin.includes('.P')
      const exchangeHasPerp = exchange && exchange.includes('_PERP')

      return coinIsPerp && !exchangeHasPerp
        ? exchange + '_PERP' + ':' + coin.replace('.P', '')
        : value.replace('.P', '')
    },
    isFullScreen(){
      return this.resizerPosWidth === 100 && this.resizerPosHeight === 100
    },
    rowIndex() {
      if (!this.label) return null

      if (this.fourWaySwitch) {
        let rowIndex = -1
        let colIndex = 0

        this.rows.findIndex((v, i) => {
          const findIndex = v?.arr?.findIndex((el, j) => {
            const isFind = el?.pair === this.label

            if (isFind) {
              rowIndex = i
              colIndex = j
            }

            return isFind
          })

          return findIndex > -1
        })

        return {
          rowIndex,
          colIndex
        }
      } else {
        if(this.isCoinDuplicate && this.currentItemId){
          return this.currentItemIndex
        }
        return this.rows.findIndex(v => v.coin.label === this.label)
      }
    },
    nextLabel () {
      if (this.fourWaySwitch)  {
        if (!this.rowIndex?.rowIndex && this.rowIndex?.rowIndex !== 0) return null
        if (!this.rowIndex?.colIndex && this.rowIndex?.colIndex !== 0) return null
        if (this.rowIndex?.rowIndex + 1 >= this.rows.length) return null

        const row = this.rows[this.rowIndex?.rowIndex + 1]?.arr

        if (this.rowIndex?.colIndex >= row?.length)
          return row?.[row?.length - 1]?.pair

        return row?.[this.rowIndex.colIndex]?.pair
      } else {
        if (!this.rowIndex && this.rowIndex !== 0) return null
        if (this.rowIndex + 1 >= this.rows.length) return null
        return this.rows[this.rowIndex + 1].coin.label
      }
    },
    nextLabelHorizontal() {
      if (this.fourWaySwitch) {
        if (!this.rowIndex?.rowIndex && this.rowIndex?.rowIndex !== 0) return null
        if (!this.rowIndex?.colIndex && this.rowIndex?.colIndex !== 0) return null

        const row = this.rows[this.rowIndex?.rowIndex]?.arr

        if (this.rowIndex?.colIndex + 1 >= row?.length) {
          if (this.rowIndex?.rowIndex + 1 >= this.rows.length) return null
          return this.rows[this.rowIndex?.rowIndex + 1]?.arr?.[0]?.pair
        }

        return row?.[this.rowIndex.colIndex + 1]?.pair
      } else return null
    },
    currentItemIndex(){
      return this.rows.findIndex(item => item.id === this.currentItemId)
    },
    prevItemId(){
      return this.rows?.[this.currentItemIndex - 1]?.id
    },
    nexItemId(){
      return this.rows?.[this.currentItemIndex + 1]?.id
    },
    prevLabel () {
      if (this.fourWaySwitch)  {
        if (!this.rowIndex?.rowIndex) return null
        if (this.rowIndex?.rowIndex - 1 < 0) return null
        if (!this.rowIndex?.colIndex && this.rowIndex?.colIndex !== 0) return null

        const row = this.rows[this.rowIndex?.rowIndex - 1]?.arr

        if (this.rowIndex?.colIndex >= row?.length)
          return row?.[row?.length - 1]?.pair

        return row?.[this.rowIndex.colIndex]?.pair
      } else {
        if (!this.rowIndex) return null
        if (this.rowIndex - 1 < 0) return null
        return this.rows[this.rowIndex - 1].coin.label
      }
    },
    prevLabelHorizontal() {
      if (this.fourWaySwitch) {
        if (!this.rowIndex?.rowIndex && this.rowIndex?.rowIndex !== 0) return null
        if (!this.rowIndex?.colIndex && this.rowIndex?.colIndex !== 0) return null

        const row = this.rows[this.rowIndex?.rowIndex]?.arr

        if (this.rowIndex?.colIndex - 1 < 0) {
          if (this.rowIndex?.rowIndex - 1 < 0) return null
          const preRow = this.rows[this.rowIndex?.rowIndex - 1]?.arr

          return preRow?.[preRow?.length - 1]?.pair
        }

        return row?.[this.rowIndex.colIndex - 1]?.pair
      } else return null
    },
    tf () {
      if (this.isMinutes && this.tfIndex === null) return '1m'
      if (this.tfIndex === null) return null
      return this.tablesIntervals[this.tfIndex]
    },
    tvInterval () {
      if (!this.tf) return null
      return tfToInterval(this.tf)
    },
    nextTf () {
      const arr = this.tablesIntervals
      const length = arr.length
      if (this.tfIndex === null || this.tfIndex === length) return null
      let index = this.tfIndex
      index++
      return arr[index]
    },
    prevTf () {
      const arr = this.tablesIntervals
      if (this.tfIndex === null || this.tfIndex === 0) return null
      let index = this.tfIndex
      index--
      return arr[index]
    },
    show () {
      return !!(
        this.label &&
        this.tf &&
        this.tvInterval
      )
    },
    prevTfText() {
      return this.prevLabelHorizontal && this.fourWaySwitch ?
        this.textControlBtn(this.prevLabelHorizontal, this.tf) :
        this.textControlBtn(this.label, this.prevTf)
    },
    nextTfText() {
      return this.nextLabelHorizontal && this.fourWaySwitch ?
        this.textControlBtn(this.nextLabelHorizontal, this.tf) :
        this.textControlBtn(this.label, this.nextTf)
    },
    tablesIntervals() {
      return this.tablesIntervalsForUse?.length ? this.tablesIntervalsForUse : this.settings.tablesIntervals
    },
    clientIdComp() {
      if(!this.clientId) {
        return this.isFutures ? CLIENT_ID_DEFAULT : CLIENT_ID_CANDLES_BINANCE_FUTURES
      }
      return this.clientId
    }
  },
  mounted() {
    const resizerPosLocalStorage = localStorage.getItem(RESIZER_POS_CANDIES_GRAPH_MODAL)
    this.resizerPosWidth = resizerPosLocalStorage ? Number(resizerPosLocalStorage) : null
    const resizerPosHeightLocalStorage = localStorage.getItem(RESIZER_HEIGHT_CANDIES_GRAPH_MODAL)
    this.resizerPosHeight = resizerPosHeightLocalStorage ? Number(resizerPosHeightLocalStorage) : null
  },
  methods: {
    closeEvent() {
      this.showModal = false
      this.notification = initialNotify.copy()
      this.showModalKey++;
    },
    openModal(notify, isFutures) {
      this.notification = cloneDeep(notify) || initialNotify.copy()
      if (isFutures !== undefined) {
        this.notification.is_futures_init = isFutures
        this.notification.is_futures = isFutures
      }
      this.showModal = true
      this.showModalKey++;
      this.isFuturesForRerender = this.isFutures
    },
    handleAddNotification() {
      this.openModal(null, this.isFuturesForRerender)
    },
    toggleFullScreenMode(){
      if(!this.isFullScreen){
        this.resizerPosWidth = 100
        this.resizerPosHeight = 100
      }else{
        this.resizerPosWidth = 70
        this.resizerPosHeight = 75
      }
      localStorage.setItem(RESIZER_POS_CANDIES_GRAPH_MODAL, this.resizerPosWidth);
      localStorage.setItem(RESIZER_HEIGHT_CANDIES_GRAPH_MODAL, this.resizerPosHeight);
    },
    setTrendsTF() {
      if (this.$route.name === 'trends' && this.show) {
        const trendsTF = JSON.parse(localStorage.getItem('trendsTF'))
        if ( trendsTF && trendsTF[`${this.label}`]) {
          this.tfIndex = trendsTF[`${this.label}`]
        }
      }
    },
    saveTrendsTF() {
      if (this.$route.name === 'trends' && this.show) {
        const trendsTF = JSON.parse(localStorage.getItem('trendsTF'))
        if (!trendsTF) {
          localStorage.setItem('trendsTF', JSON.stringify({[`${this.label}`]: this.tfIndex}))
        } else {
          trendsTF[`${this.label}`] = this.tfIndex
          localStorage.setItem('trendsTF', JSON.stringify(trendsTF))
        }
      }
    },
    textControlBtn (t1, t2) {
      // if (window.innerWidth <= 768) {
      //   return t2
      // }
      if (!t1 || !t2) return '—'
      return t1 + ' ' + t2
    },
    openCandlesGraph (label, tfIndex, id, ts) {
      this.label = label
      this.currentItemId = id
      this.tfIndex = tfIndex
      this.ts = ts
      document.body.style.overflow = 'hidden'
      window.addEventListener('keydown', this.keyDown)
    },
    closeGraph () {
      document.body.style.overflow = ''
      this.labelOld = this.label
      this.tfIndexOld = this.tfIndex
      this.currentItemIdOld = this.currentItemId
      this.label = null
      this.currentItemId = null
      this.tfIndex = null
      this.altTfLabel = ''
      window.removeEventListener('keydown', this.keyDown)
      this.$emit('close')
    },
    toggleModal() {
      this.closeGraph()

      this.$nextTick(() => {
        this.openCandlesGraph(this.labelOld, this.tfIndexOld)
      })
    },
    prevTfHandler() {
      if (!this.loadingData && this.chartReady) {
        this.prevLabelHorizontal && this.fourWaySwitch ? this.label = this.prevLabelHorizontal : this.tfIndex--
      }
    },
    prevLabelHandler() {
      if (!this.loadingData && this.chartReady) {
        this.label = this.prevLabel
        this.currentItemId = this.prevItemId
      }
    },
    nextLabelHandler() {
      if (!this.loadingData && this.chartReady) {
        this.label = this.nextLabel
        this.currentItemId = this.nexItemId
      }
    },
    nextTfHandler() {
      if (!this.loadingData && this.chartReady) {
        this.nextLabelHorizontal && this.fourWaySwitch ? this.label = this.nextLabelHorizontal : this.tfIndex++
      }
    },
    keyDown (e) {
      if(this.showModal){
        return
      }
      if (e.keyCode === 27) {
        this.closeGraph()
        window.removeEventListener('keydown', this.keyDown)
      }

      if (!this.chartReady || this.loadingData){
        return
      }

      if (e.keyCode === 37 && (this.prevTf || this.prevLabelHorizontal && this.fourWaySwitch)) {
        e.preventDefault()
        this.prevTfHandler()
      }
      if (e.keyCode === 40 && this.nextLabel) {
        e.preventDefault()
        this.nextLabelHandler()
      }
      if (e.keyCode === 39 && (this.nextTf || this.nextLabelHorizontal && this.fourWaySwitch)) {
        e.preventDefault()
        this.nextTfHandler()
      }
      if (e.keyCode === 38 && this.prevLabel) {
        e.preventDefault()
        this.prevLabelHandler()
      }
    },
    onIntervalChanged(interval) {
      if(this.tfExceptions.includes(interval)) {
        this.altTfLabel = intervalToTf(interval)
      }else{
        this.altTfLabel = ''
      }
      const index = this.tablesIntervals.findIndex(el => el === intervalToTf(interval))

      if (index > -1) {
        this.tfIndex = index
      }
    },
    onPlusClick(coordinates) {
      if (!coordinates.symbol || !Array.isArray(this.pairsData)) return
      const arr = coordinates.symbol.split(':')
      const exchange = this.isFutures ? arr[0].replace('_PERP', '') : arr[0]
      const symbol = this.isFutures ? arr[1] + '.P' : arr[1]
      if (exchange && symbol) {
        const pair = this.pairsData.find(el => {
          return (
            el.exchange === exchange &&
            el.symbol === symbol
          )
        })
        if (!pair) return console.error('Pair not found')
        this.showModal = true
        this.notification = new Notification(
          {
            pair: pair,
            is_futures: this.isFutures,
            conditions: [
              new Condition(
                {
                  tf: 'price',
                  var: "price_crossing",
                  value: pair.hasOwnProperty('precession') && pair.precession !== null ? coordinates.price.toFixed(pair.precession) : coordinates.price
                }
              )
            ]
          }
        )
      }
    },
    resizerStart(e) {
      e.preventDefault()
      const move = throttle(e => {
        const clientWidth = document.documentElement.clientWidth;
        const clientHeight = document.documentElement.clientHeight;

        const right = clientWidth - e.clientX;
        const resizerPosWidth = 100 * (e.clientX - right) / clientWidth;
        this.resizerPosWidth = Math.min(Math.max(resizerPosWidth, 40), 100);

        const deltaY = clientHeight - e.clientY
        const resizerPosHeight = 100 * (e.clientY - deltaY) / clientHeight;
        this.resizerPosHeight = Math.min(Math.max(resizerPosHeight, 40), 100);
      }, 50);

      this.resizerMoving = true;
      document.addEventListener('mousemove', move);
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', move);
        this.resizerMoving = false;

        localStorage.setItem(RESIZER_POS_CANDIES_GRAPH_MODAL, this.resizerPosWidth);
        localStorage.setItem(RESIZER_HEIGHT_CANDIES_GRAPH_MODAL, this.resizerPosHeight);
      }, {once: true});
    },

    async editSignal(el){
      const res = await axios.get('api/v1/group_by_signal_id/' + el.signalId)
      if(!res?.data?.data){
        return
      }
      this.notification = new Notification({...res.data.data, ediMode: true})
      this.showModal = true
    }
  },
  watch:{
    isFutures (val) {
      this.showModalKey++
      this.notification = createInitialNotify(val)
    },
    isFuturesForRerender() {
      this.closeEvent()
      this.$nextTick(() => {
        this.handleAddNotification()
      })
    },
    show(val){
      if(val){
        document.getElementsByTagName('body')[0].classList.add("hideSupport")
      }else{
        const routeNames = ['trends', 'tablesview', 'trendsview', 'graphsview', 'treemap', 'notifications']

        if(!routeNames.some(item => item === this.$route.name)){
          document.getElementsByTagName('body')[0].classList.remove("hideSupport")
        }
      }
      this.setTrendsTF()
      this.saveTrendsTF()
    },
    tfIndex(){
      this.saveTrendsTF()
    },
    label(){
      this.saveTrendsTF()
    }
  }
}
</script>
<style lang="scss" scoped>

.custom-title {
  padding-left: 0.25rem;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.control {
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--dark);
  width: 2rem;
  height: 2rem;
  color: #ffffff; // var(--white);
  background: transparent;
  outline: none;
  transition: all ease-in-out .2s;
  @include media-breakpoint-down(md) {
    display: none;
  }
  &:disabled {
    background: transparent !important;
    cursor: not-allowed;
  }
  &:hover {
    background: var(--primary);
  }
  &._left {
    transform: translateY(-50%);
    top: 50%;
    left: -5rem;
    &:after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 2.5rem;
    }
  }
  &._right {
    transform: translateY(-50%);
    top: 50%;
    right: -5rem;
    &:after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 2.5rem;
    }
  }
  &._top {
    top: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    &:after {
      content: attr(data-text);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      padding-left: 2.5rem;
    }
  }
  &._bottom {
    bottom: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    &:after {
      content: attr(data-text);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      padding-left: 2.5rem;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.control-mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  color: var(--white);
  background: transparent;
  outline: none;
  margin: 0 0.5rem;
  padding: 0;
  border: none;
  transition: all ease-in-out .2s;
  .cbtn-icon {
    display: inline-block;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid var(--dark);
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25;
  }
  .cbtn-text {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &:disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.graph-custom-modal {
  &__loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;

    .spinner {
      width: 4rem;
      height: 4rem;
    }
  }
  /deep/ {
    .modal {
      //z-index: 1000000;
    }
    .modal-dialog {
      @include media-breakpoint-up(lg) {
        width: var(--width, 75vw);
        max-width: none;
        min-width: 40vw;
        margin: auto;
      }

      // @media (min-width: 1300px) {
      //   max-width: 80vw;
      // }

      // @media (min-width: 1600px) {
      //   max-width: 85vw;
      // }

      @include media-breakpoint-down(md) {
        width: 100%;
        height: 100%;
        margin: 0;
        max-width: unset;
        max-height: unset;
      }
    }
    .modal-content {
      height: var(--height, 75vh);

      @include media-breakpoint-down(md) {
        height: 100%;
      }
    }
    .modal-body {
      background: inherit;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
    .modal-footer {
      display: none;
    }
    .modal-header {
      position: relative;
    }

    .candles-graph._move {
      pointer-events: none;
    }
  }
}

.notification-modal {
  /deep/ {
    .modal-footer {
      display: flex;
    }

    .modal-dialog {
      min-width: unset;
      width: auto;
      @include media-breakpoint-up(sm) {
        max-width: 500px;
      }
    }

    .modal-body {
      height: auto;
    }
  }
}


.modal-resizer {
  display: flex;
  align-items: end;
  user-select: none;
  cursor: col-resize;
  position: absolute;
  right: 0;
  bottom: 0;

  &__inner {
    height: 100%;
    background: var(--dark);
    width: 2px;
    transition: all 250ms;

    &._move {
      color: var(--primary);
      box-shadow: 0 0 5px 1px var(--primary);
    }
  }

  &:hover {
    .modal-resizer__inner {
      background: var(--primary);
      box-shadow: 0 0 5px 1px var(--primary);
    }
  }
}
.fullscreen-icon{
  transition: all ease-in-out .2s;
  &:hover{
    color: var(--primary)
  }
}
</style>
