var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-popover",
    _vm._g(
      _vm._b(
        {
          attrs: {
            handleResize: false,
            open: _vm.show,
            trigger: "manual",
            container: ".c-app",
            placement: "right",
          },
          on: {
            show: function ($event) {
              return _vm.togglePopover(true)
            },
            hide: function ($event) {
              return _vm.togglePopover(false)
            },
          },
        },
        "v-popover",
        _vm.popoverOptions,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          staticClass: "flag-wrap",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.togglePopover(true)
            },
          },
        },
        [
          _vm._l(_vm.currentWatchLists, function (list, i) {
            return _c("CIcon", {
              key: i,
              staticClass: "flag",
              class: list.color ? `text-${list.color}` : "",
              style: { color: list.customColor },
              attrs: { width: "1rem", name: "flag" },
            })
          }),
          !_vm.currentWatchLists.length
            ? _c("CIcon", {
                staticClass: "flag text-white",
                staticStyle: { opacity: "0.1" },
                attrs: { width: "1rem", name: "flag" },
              })
            : _vm._e(),
        ],
        2
      ),
      _c("template", { slot: "popover" }, [
        _c(
          "div",
          { staticClass: "popup" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.show
                ? _c(
                    "div",
                    { staticClass: "popup__inner" },
                    _vm._l(_vm.watchLists, function (list, i) {
                      return _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: list.name,
                              expression: "list.name",
                            },
                          ],
                          key: i,
                          staticClass: "watch-btn",
                          class: list.color
                            ? `bg-${list.color}`
                            : "_custom-color",
                          style: { background: list.customColor },
                          on: {
                            click: function ($event) {
                              _vm.setPair(i, _vm.isCurrentWatchList(i))
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.isCurrentWatchList(i) ? "✓" : "") +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }